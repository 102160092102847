import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Sue Williams" subtitle="Primary Principal" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "663px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/aa60f01d7fd3a3a1378dccb1ffe5baeb/b9214/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQL/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQD/2gAMAwEAAhADEAAAAcqufWuwjsYaLELFkHBZ/8QAHBAAAgMAAwEAAAAAAAAAAAAAAQIAAwQREhMi/9oACAEBAAEFAkXkvn6rKyFa4jzmdPS3T8CY7BW+h+9s/8QAFxEBAQEBAAAAAAAAAAAAAAAAARACEf/aAAgBAwEBPwHAJB4T/8QAFxEBAQEBAAAAAAAAAAAAAAAAARACEf/aAAgBAgEBPwHajE6z/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECEBEhMkFR/9oACAEBAAY/AjdZZ1SQo+1tciTr/8QAGxAAAwADAQEAAAAAAAAAAAAAAAEhETFRYUH/2gAIAQEAAT8hhsptTAk6Bp9l58Hs8krOUdMnBpWRxng3ivCE4f/aAAwDAQACAAMAAAAQVz2y/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EHq2zYlhf//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxA6DZFq2t//xAAeEAEAAwACAgMAAAAAAAAAAAABABEhMUFxsVFhof/aAAgBAQABPxADah7mVbcJdeI6iC4RSrv6mxIpxwuAtXEcMst4gv3UGPw7sDj99TW6i3SAHwjmEKJ6DJQJ/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/aa60f01d7fd3a3a1378dccb1ffe5baeb/b9214/01.jpg",
              "srcSet": ["/static/aa60f01d7fd3a3a1378dccb1ffe5baeb/f93b5/01.jpg 300w", "/static/aa60f01d7fd3a3a1378dccb1ffe5baeb/b4294/01.jpg 600w", "/static/aa60f01d7fd3a3a1378dccb1ffe5baeb/b9214/01.jpg 663w"],
              "sizes": "(max-width: 663px) 100vw, 663px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`It has been a fun and eventful year with lots of learning taking place in the Primary School. Our reading and writing programme and pedagogy of the workshop model was embedded into many of our units of inquiry.`}</p>
    </blockquote>
    <p>{`Seesaw became a very important tool of communication with children and parents, especially as we moved into distance learning in March, and grade levels shared their learning with parents through our Sharing Our Learning events.`}</p>
    <p>{`Throughout the school year, our students chose topics to investigate and inquire into. From our Early Childhood students who decided they wanted to learn more about the sea and turned their learning environment into an ocean filled with fish to our Grade 5 students who researched their own individual passions and central ideas during their Exhibition unit.  Our Grade 3 and 4 students developed research skills to get them ready as they come ever closer to the end of their PYP experience in Grade 5.  And all of our Grade 2 - 5 students learned how to use Google Classroom to work virtually with their peers and teachers.`}</p>
    <p>{`While distance learning was difficult at first, parents and faculty alike saw the progress toward independence that the majority of our students experienced.  Children had to not only keep track of their work, but they needed to also manage their time as they met with teachers and peers during Zoom discussions and lessons, choose an ECA, or decide on what to work on during the asynchronous time in distance learning.`}</p>
    <p>{`These distance learning platforms - Seesaw, Google Classroom and Zoom - all helped in the teaching and learning of the curriculum. Students still learned their math, handed in writing tasks and continued to develop in their reading skills. PSPE, Art, Music and Additional Languages continued to be offered throughout our time off campus and many students took the opportunity to do all the tasks assigned, reflecting on their progress and continuing to learn.`}</p>
    <p>{`As we look to the future, the Primary School will incorporate more technology into everyday learning so that children will continue to feel comfortable in their learning, no matter where it may take place.`}</p>
    <p>{`It was an amazing year working with all of our students, parents and PSI faculty and staff members.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      